import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  IActivitySchema,
  IModuleData,
  IProgramData,
} from "../../interfaces/dashboard.interface";
import axios from "axios";
import { API_URL } from "../../app/api";
import { revertAll } from "../../lib/store";

interface ReportData {
  activity_id: string;
  email_id: string;
  learner_id: string;
  learner_name: string;
  module_id: string;
  organization_id: string;
  program_id: string;
  super_admin: boolean;
  test_id: string;
}

export const generateUrlAndReport = createAsyncThunk(
  "program/generateUrlAndReport",
  async (data: ReportData) => {
    const payload = {
      activity_id: data.activity_id,
      email_id: data.email_id,
      learner_id: data.learner_id,
      learner_name: data.learner_name,
      module_id: data.module_id,
      organization_id: data.organization_id,
      program_id: data.program_id,
      super_admin: data.super_admin,
      test_id: data.test_id,
    };
    const response = await axios.post(`${API_URL}create-report`, payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response;
  }
);

export const fetchAllProgramData = createAsyncThunk(
  "program/fetchAllProgramData",
  async () => {
    const response = await axios.post(`${API_URL}learnerprograms`, {});

    return response.data.programs;
  }
);

// Async thunk to save video progress
export const saveProgress = createAsyncThunk(
  "program/saveProgress",
  async (payload: {
    superAdminId: string;
    organizationId: string;
    learnerId: string;
    programId: string;
    moduleId: string;
    activityId: string;
    progress: number;
    status: string;
  }) => {
    const response = await axios.post(
      `${API_URL}save-video-progress`,
      {
        superAdminId: payload.superAdminId,
        organizationId: payload.organizationId,
        learnerId: payload.learnerId,
        program_id: payload.programId,
        module_id: payload.moduleId,
        activity_id: payload.activityId,
        progressValue: payload.progress,
        statusValue: payload.status,
      },
      { params: { loader: false } }
    );
    return { ...response.data, payload };
  }
);


interface IProgramState {
  program: IProgramData[];
  filters: any;
  status: string;
  selectedState: {
    selectedModule: IModuleData | undefined;
    selectedActivity: IActivitySchema | undefined;
  };
}
const initialState: IProgramState = {
  program: [],
  filters: "",
  status: "",
  selectedState: {
    selectedModule: undefined,
    selectedActivity: undefined,
  },
};

export const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllProgramData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllProgramData.fulfilled, (state, action) => {
      state.status = "success";
      state.program = action.payload;
    });
    builder.addCase(fetchAllProgramData.rejected, (state) => {
      state.status = "failed";
    });
    builder.addCase(generateUrlAndReport.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(generateUrlAndReport.fulfilled, (state, action) => {
      state.status = "success";
      const assessmentURL = action.payload.data?.data?.auto_login_url;
      window.open(assessmentURL, "_blank");
      const reqData = JSON.parse(action.payload.config.data);
      const oldProgram = state.program;
      const updatedData = oldProgram.map((p) => {
        if (p._id === reqData.program_id) {
          const updateModules = p.modules_list.map((m) => {
            if (m._id === reqData.module_id) {
              const updateActivityList = m.activity_list.map((a) => {
                if (a._id === reqData.activity_id) {
                  a.auto_login_url = assessmentURL;
                  // a.data.activity_url = assessmentURL;
                }
                return a;
              });
              return { ...m, activity_list: updateActivityList };
            }
            return m;
          });
          return { ...p, modules_list: updateModules };
        }
        return { ...p };
      });

      state.program = updatedData;
    });
    builder.addCase(generateUrlAndReport.rejected, (state) => {
      state.status = "failed";
    });
     // Save Progress
     builder.addCase(saveProgress.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(saveProgress.fulfilled, (state, action) => {
      state.status = "success";
      console.log("Progress saved successfully:", action.payload);
      const { programId, moduleId, activityId, progress } = action.payload.payload;
      const oldProgram = state.program;
      const updatedData = oldProgram.map((p) => {
        console.log(p)
        if (p._id === programId) {
          console.log(programId)
          const updateModules = p.modules_list.map((m) => {
            if (m._id === moduleId) {
              console.log(moduleId)
              const updateActivityList = m.activity_list.map((a) => {
                if (a._id === activityId) {
                  console.log(activityId, progress)
                  a.report_data[0].progress = progress;
                }
                return a;
              });
              return { ...m, activity_list: updateActivityList };
            }
            return m;
          });
          return { ...p, modules_list: updateModules };
        }
        return { ...p };
      });

      state.program = updatedData;
    });
    builder.addCase(saveProgress.rejected, (state) => {
      state.status = "failed"
    });
    builder.addCase(revertAll, () => initialState);
  },
  
});
export const programActions = programSlice.actions;

export default programSlice.reducer;
