import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { revertAll } from "../../lib/store";

interface HeaderState {
  pageTitle: string;
  noOfNotifications: number;
  newNotificationMessage: string;
  newNotificationStatus: number;
}

const initialState: HeaderState = {
  pageTitle: "Home",
  noOfNotifications: 0,
  newNotificationMessage: "",
  newNotificationStatus: 0,
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setPageTitle: (state, action: PayloadAction<{ title: string }>) => {
      state.pageTitle = action.payload.title;
    },

    removeNotificationMessage: (state) => {
      state.newNotificationMessage = "";
    },

    showNotification: (
      state,
      action: PayloadAction<{ message: string; status: number }>
    ) => {
      state.newNotificationMessage = action.payload.message;
      state.newNotificationStatus = action.payload.status;
    },
    resetAll: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(revertAll, () => initialState);
  },
});

export const { setPageTitle, removeNotificationMessage, showNotification } =
  headerSlice.actions;

export default headerSlice.reducer;
