import React from "react";
import { SortChartType } from "./report.interface";
interface RadioButtonProps {
  selected: SortChartType;
  handleChange: (data: SortChartType) => void;
}
const RadioButton = ({ selected, handleChange }: RadioButtonProps) => {
  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as "average" | "max" | "latest";
    handleChange(selectedValue);
  };
  return (
    <div className="md:flex justify-evenly text-xl font-semibold">
      <label>
        <input
          type="radio"
          value="latest"
          checked={selected === "latest"}
          onChange={handleChangeEvent}
          style={{marginInline:"10px"}}
        />
        Latest Score
      </label>
      <br />
      <label>
        <input
          type="radio"
          value="max"
          checked={selected === "max"}
          onChange={handleChangeEvent}
          style={{marginInline:"10px"}}
        />
        Maximum Score
      </label>
      <br />
      <label>
        <input
          type="radio"
          value="average"
          checked={selected === "average"}
          onChange={handleChangeEvent}
          style={{marginInline:"10px"}}
        />
        Avg Score
      </label>
      <br />
    </div>
  );
};
export default RadioButton;
