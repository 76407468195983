import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ANALYTICS_API_URL } from "../../services";
//import userData from "../../hooks/useData";
import PieChart1 from "./PieChart1";
import "../../styles/Piechart.css";
import AssessmentPerformanceChart from "./Assessment";
import RadioButton from "./RadioButton";
import { SortChartType } from "./report.interface";
import { useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import Navbar from "../../components/Navbar";
import SideBar from "../../components/SideBar";
import axios from "axios";

export const defaultBarLineChartData = {
  labels: [],
  campus_percentile: [],
  data: [],
  overall_percentile: [],
};

export interface IChartData {
  labels: string[];
  series: number[];
}
interface ILearnerAnalyticsResponse {
  program_status: IChartData;
  assessment_status: IChartData;
  assessment_performance: {};
}

const LearnerAnalytics = () => {
  //const { user} = userData();
  const user = useSelector((state: RootState) => state.user.user);
  const program = useSelector((state: RootState) => state.program.program);
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(
    program?.[0]?._id || null
  );
  const [selected, setSelected] = useState<SortChartType>("average");
  console.log(program);

  const [data, setData] = useState<ILearnerAnalyticsResponse>({
    program_status: { labels: [], series: [] },
    assessment_status: { labels: [], series: [] },
    assessment_performance: {
      average: defaultBarLineChartData,
      max: defaultBarLineChartData,
      latest: defaultBarLineChartData,
    },
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${ANALYTICS_API_URL}/learner_level_response`, {
        params: {
          learner_id: user?._id,
          program_id: selectedProgramId,
        },
        withCredentials:true
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching learner data:", error);
    }
  }, [user._id, selectedProgramId]);

  useEffect(() => {
    if (user && user._id) {
      void fetchData();
    }
  }, [fetchData, user, user._id]);

  // Extract the labels and series based on the selected value (avg, max, latest)
  const assessmentData = useMemo(() => {
    return data.assessment_performance[selected];
  }, [selected, data]);

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <h1 className="flex">Learner Analytics</h1>
      <div style={{ background: "white", width: "100%", padding: "2em" }}>
        <div className="chart-container">
          <div className="chart-item">
            <PieChart1
              header_data={"Program Completion Status"}
              data={data.program_status}
            />
          </div>
          <div className="chart-item">
            <PieChart1
              header_data={"Assessment Completion Status"}
              data={data.assessment_status}
            />
          </div>
        </div>
      </div>
      <div style={{ background: "white", width: "100%", padding: "2em" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
          }}
        >
          <p style={{ fontSize: "1.3em", fontWeight: "bold" }}>
            Select a program
          </p>
          <span style={{ width: "1em" }} />
          <select
            value={selectedProgramId || ""}
            onChange={(e) => setSelectedProgramId(e.target.value)}
            className="border p-2 rounded-md"
          >
            {program?.map((p: { _id: string; program_name: string }) => (
              <option key={p._id} value={p._id}>
                {p.program_name}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            paddingTop: "2em",
          }}
        >
          <p style={{ fontSize: "1.3em", fontWeight: "bold" }}>
            Select Score Type:
          </p>
          <span style={{ width: "1em" }} />
          <RadioButton selected={selected} handleChange={setSelected} />
        </div>
        <AssessmentPerformanceChart
          title="Assessment Performance Status"
          data={assessmentData}
        />
      </div>
    </div>
  );
};

export default LearnerAnalytics;
