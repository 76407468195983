export const ProverbService = {
  getData() {
    return [
      { title: "An investment in knowledge pays the best interest." },
      {
        title: "Knowledge is power.",
      },
      {
        title: "Teach a man to fish, and you feed him for a lifetime.",
      },
      {
        title: "A wise man never stops learning.",
      },
      {
        title: "The more you learn, the more you earn.",
      },
      {
        title:
          "The beautiful thing about learning is that no one can take it away from you.",
      },
      {
        title:
          "Education is the key to unlocking the world, a passport to freedom.",
      },
      {
        title: "Skills are wealth that grow with use.",
      },
    ];
  },

  getProverbs() {
    return Promise.resolve(this.getData());
  },
};
