import React, { useRef, useEffect } from "react";
import { RootState } from "@/lib/store";
import { useDispatch, useSelector } from "react-redux";
import { Menubar } from "primereact/menubar";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
// import TelegramButton from "@/pages/programs/TelegramButton";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { logout } from "../features/common/userSlice";

export default function Navbar() {
  //   const logoTemplate = () => <Image src={logo} alt="Image" width="250" />;
  const { user, iconData } = useSelector((state: RootState) => state.user);
  const menuRight = useRef<Menu>(null);
  const navigate = useNavigate();
  const id = user?._id || "";
  const dispatch = useDispatch();
  const handleLogoutClick = () => {
    // Perform any logout logic here if needed
    // console.log("Logout id:",id);
    localStorage.removeItem("persist:root");
    if (user && user._id) {
      dispatch(logout({ user })); 
    }
    navigate("/login");
  };
  
  useEffect(()=>{
    if(iconData){
      updateFavicon(iconData?.favicon)
    }
  },[user])
  
  
  const updateFavicon = (faviconUrl: string) => {
    //console.log('updateFavicon called with URL:', faviconUrl)
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.type = 'image/x-icon'
    link.href = faviconUrl
    //console.log('Favicon updated to:', link.href)
  }

  const items = [
    { label: "Profile", icon: "pi pi-user", url: "/profile" },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: () => {
        void handleLogoutClick();
      },
    },
  ];

  const start = (
    <img
      alt="logo"
      style={{ height: "50px" }}
      src={iconData?.logo || "https://i.ibb.co/7xbwRjdp/sflLogo.png"}
      onClick={()=>{navigate("/dashboard")}}
      className="mr-2"
    ></img>
  );
  const end = (
    <div className="flex align-items-center gap-2">
      {/* <TelegramButton /> */}
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
      />

      <Button
        label={user?.learner_name || "Guest"}
        className="w-full p-link flex align-items-center gap-2 p-2 text-color hover:surface-200 border-noround"
        onClick={(event) => menuRight?.current?.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
      >
        {
          <Avatar
            image={
              user?.profile_pic ||
              "https://i.postimg.cc/jqncNNcB/default-profile.png"
            }
            className="mr-2"
            shape="circle"
          />
        }
      </Button>
    </div>
  );

  return <Menubar start={start} end={end} className="shadow-2" />;
}
