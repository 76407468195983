import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { API_URL } from "../../app/api";
import Cookies from "js-cookie";
export const getOrganization = createAsyncThunk(
  "login/getOrganizations",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}getOrganizationData`, {
        username: email,
        role: "learner",
      });
      return response.data.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (
    {
      email,
      password,
      organization,
    }: { email: string; password: string; organization: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${API_URL}learnerauth`, {
        username: email,
        password: password,
        organization_id: organization,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "login/sendPasswordResetEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}learner_resetPassword`, {
        email,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const updatePassword = createAsyncThunk(
  "login/updatePassword",
  async (
    {
      email,
      otp,
      newPassword,
      organization,
    }: {
      email: string;
      otp: string;
      newPassword: string;
      organization: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${API_URL}learner_updatePassword`, {
        email,
        otp,
        password: newPassword,
        organization_id: organization,
      });
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.response?.data?.error ?? "Error");
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);
export const logout = createAsyncThunk(
  "logout/user",
  async ({ user }: { user: { _id: string } }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}logout`, {
        id: user._id,
      });
      console.log(response.data);
      localStorage.removeItem("persist:root");
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
      return response.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

const initialState = {
  email: "",
  organization: "",
  organizations: [],
  isLoading: false,
  error: null,
  user: null,
  token: null,
  otpValidated: false,
  iconData:null
};
const userSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
    resetOrganizations: (state) => {
      state.organization = "";
      state.organizations = [];
    },
    logoutUser: (state) => {
      state.token = null;
      state.user = null;
      state.iconData=null;
    },
    setIconData:(state,action)=>{
      state.iconData=action.payload;
    },
    resetAll: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getOrganization.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      state.isLoading = false;
      state.organizations = action.payload;
      if (action.payload.length === 1) {
        state.organization = action.payload[0]._id;
      }
    });
    builder.addCase(getOrganization.rejected, (state) => {
      state.isLoading = false;
      // state.error = action.error.message;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.token = action.payload.accessToken;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.isLoading = false;
      // state.error = action.payload.error;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.isLoading = false;
      state.otpValidated = false;
    });
    builder.addCase(updatePassword.rejected, (state) => {
      state.isLoading = false;
      // state.error = action.payload.error;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.token = null;
      state.user = null;
    });
  },
});

export const { setEmail, setOrganization, resetOrganizations,setIconData } =
  userSlice.actions;
export default userSlice.reducer;
