import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { type IBarLineChartResponse } from "./report.interface";

type BarLineChartProps = {
  data: IBarLineChartResponse;
  title: string;
};

const AssessmentPerformanceChart: React.FC<BarLineChartProps> = ({
  data,
  title,
}) => {
  const series = [
    {
      name: "My Score",
      type: "column",
      data: data.data,
    },
    {
      name: "Campus Percentile",
      type: "line",
      data: data.campus_percentile,
    },
    {
      name: "Overall Percentile",
      type: "line",
      data: data.overall_percentile,
    },
  ];
  const options: ApexOptions = {
    chart: {
      height: 650,
      type: "line",
    },
    stroke: {
      width: [0, 4],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: function (val) {
        return val.toString();
      },
    },
    labels: data.labels,
    xaxis: {
      labels: {
        style: {
          fontSize: "12px",
          colors: "#000",
        },
        offsetX: -10,
        offsetY: 0,
        rotate: -45,
      },
    },
    yaxis: [
      {
        title: {
          text: "Score",
        },
        labels: {
          formatter: function (val) {
            //return val.toFixed(0);
          },
        },
      },
    ],
  };

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4">
        <h1 className="font-bold">{title}</h1>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
          width="800"
        />
      </div>
    </div>
  );
};

export default AssessmentPerformanceChart;
