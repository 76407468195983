import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  setEmail,
  setOrganization,
  getOrganization,
  resetOrganizations,
  loginUser,
  sendPasswordResetEmail,
  updatePassword,
  setIconData,
} from "../../features/common/userSlice";
import { AppDispatch, RootState } from "@/lib/store";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { useLoaderAndToast } from "../../ToastProvider";

function Login() {
  const [currentPage, setCurrentPage] = useState("login");
  const { showToast } = useLoaderAndToast();
  const dispatch: AppDispatch = useDispatch();
  const { email, organization, organizations, isLoading } = useSelector(
    (state: RootState) => state.user
  );
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const typingTimeout = useRef<NodeJS.Timeout | null>(null);

  const onForgotPassword = () => {
    setCurrentPage("forgotpassword");
  };
  const onBacktoLogin = () => {
    setCurrentPage("login");
  };

  const handleLogin = () => {
    if (!email || !password || !organization) {
      showToast("Error", "error");
      return;
    }

    try {
      dispatch(loginUser({ email, password, organization })).then(
        (response) => {
          if (response.payload.message === "User session exists") {
            showToast(
              "You were logged in from another session. Your previous session has been replaced.",
              "success"
            );
            navigate("/dashboard");
            dispatch(setIconData(response.payload.iconData));
            Cookies.set("access_token", response.payload.access_token)
          } else if(response.payload.message==="Incorrect Password"){
            showToast("Invalid Password","error");
          }
          else if (response.payload.success) {
            showToast("Login Successful", "success");
            navigate("/dashboard");
            dispatch(setIconData(response.payload.iconData));
            Cookies.set("access_token", response.payload.access_token)
          } else {
            showToast("Login Failed", "error");
          }
        }
      );
    } catch {
      showToast("Login Failed", "error");
    }
  };

  const handleSendMail = () => {
    // if (!email  || !organization) {

    //   return;
    // }
    dispatch(sendPasswordResetEmail(email))
      .unwrap()
      .then((response) => {
        console.log(response.data);
        showToast("Email sent", "success");

        setCurrentPage("resetPassword");
      })
      .catch((err) => {
        showToast("Error", "error");
        console.log(err);
      });
  };

  const handleResetPassword = () => {
    if (!otp || !newPassword || !email || !organization) {
      showToast("Error", "error");
      return;
    }

    dispatch(
      updatePassword({
        email,
        otp,
        newPassword,
        organization,
      })
    )
      .unwrap()
      .then(() => {
        showToast("Success", "success");
        onBacktoLogin();
      })
      .catch((error) => {
        console.error("Error:", error);
        showToast("Error", "error");
      });
  };

  const checkIsEmail = (value: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  };

  const handleEmailChange = (value: string) => {
    dispatch(setEmail(value));
    if (typingTimeout.current) clearTimeout(typingTimeout.current);
    typingTimeout.current = setTimeout(() => {
      if (value === "" || !checkIsEmail(value)) {
        dispatch(resetOrganizations());
        setPassword("");
        return;
      }
      dispatch(getOrganization(value));
    }, 500);
  };

  const organizationOptionTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props?.placeholder}</span>;
  };

  return (
    <div className="">
      {currentPage === "login" && (
        <>
          <h1 className="mb-2 font-bold text-2xl text-gray-600 text-center">
            Welcome Back
          </h1>
        </>
      )}
      {currentPage === "forgotpassword" && (
        <>
          <h1 className="mb-2 font-bold text-2xl text-gray-600 text-center">
            Forgot Password
          </h1>
        </>
      )}
      {currentPage === "resetPassword" && (
        <>
          <h1 className="mb-2 font-bold text-2xl text-gray-600 text-center">
            Update Password
          </h1>
        </>
      )}
      <div className="flex flex-col text-left">
        <label htmlFor="email" className="font-bold">
          Email Address
        </label>
        <InputText
          id="email"
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          required
          className="w-full mb-4 border border-gray-300 rounded-md p-2 mt-2"
        />

        <label htmlFor="organization" className="font-bold">
          Choose Organization
        </label>
        <Dropdown
          id="organization"
          value={organization}
          disabled={
            !email || organizations.length === 1 || !checkIsEmail(email)
          }
          valueTemplate={organizationOptionTemplate}
          options={organizations?.map(
            (org: {
              super_admin: string;
              organization_name: string;
              _id: string;
            }) => ({
              label: `${org.super_admin}-${org.organization_name}`,
              value: org._id,
            })
          )}
          onChange={(e) => dispatch(setOrganization(e.value))}
          // itemLabel="label"
          optionLabel="label"
          optionValue="value"
          showClear={organizations?.length > 1}
          placeholder="Select an organization"
          required
          className="w-full mb-4 border border-gray-300 rounded-md p-0 mt-2"
        />

        {currentPage === "login" && (
          <>
            <label htmlFor="password" className="font-medium mb-1">
              Password
            </label>
            <div className="relative w-full mb-4">
              <input
                id="password"
                type={passwordVisible ? "text" : "password"}
                value={password}
                disabled={!email || !organization}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full pr-10 px-3 py-2 border-2 rounded-md focus:ring focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={() => setPasswordVisible((prev) => !prev)}
                className="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-gray-700"
              >
                {passwordVisible ? "👁️" : "🙈"}
              </button>
            </div>

            <div className="flex justify-end items-center">
              <Button
                label="Forgot Password?"
                onClick={onForgotPassword}
                className="text-blue-500 hover:underline"
              />
            </div>

            <Button
              onClick={handleLogin}
              disabled={isLoading || !email || !organization || !password}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
              label={isLoading ? "Loading..." : "Login"}
            />
          </>
        )}
        {currentPage === "forgotpassword" && (
          <>
            <div className="flex justify-end items-center">
              <Button
                label="Back to Login"
                onClick={onBacktoLogin}
                className="text-blue-500 hover:no-underline"
              />
            </div>

            <Button
              label={isLoading ? "Loading..." : "Send Mail"}
              onClick={handleSendMail}
              disabled={isLoading || !email || !organization}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
            />
          </>
        )}

        {currentPage === "resetPassword" && (
          <>
            <label htmlFor="otp" className="mb-2 font-bold ml-3">
              OTP
            </label>
            <InputText
              id="otp"
              type="text"
              placeholder="Your OTP from mail."
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              className="w-full mb-4 ml-3 rounded-xl border border-gray-400 p-1"
            />

            <label htmlFor="password" className="mb-3 font-bold ml-3">
              New Password
            </label>
            <Password
              id="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Password"
              pt={{ input: { className: "w-full" } }}
              className="w-full mb-4 ml-3 border border-gray-300 rounded-xl p-1 mr-2"
            />

            <div className="flex justify-end items-center">
              <Button
                label="Back to Login"
                onClick={onBacktoLogin}
                className="text-blue-500 hover:no-underline"
              />
            </div>

            <Button
              label={isLoading ? "Loading..." : "Reset Password"}
              onClick={handleResetPassword}
              disabled={isLoading}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
