import programSlice from "../features/common/programSlice";
import headerSlice from "../features/common/headerSlice";
import userSlice from "../features/common/userSlice";
import { combineReducers, createAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Create persist config
const persistConfig = {
  key: "root",
  storage,
};

export const revertAll = createAction('REVERT_ALL')
// Combine reducers
const rootReducer = combineReducers({
  program: programSlice,
  header: headerSlice,
  user: userSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

// Create persistor
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
