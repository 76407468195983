import React, { useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import "primeicons/primeicons.css"; 

export default function SideBar() {
  const [visible, setVisible] = useState<boolean>(false);

  const currentPath = window.location.pathname;

  const menuItems = [
    { path: "/dashboard", label: "Dashboard", icon: "pi pi-home" },
    // { path: "/profile", label: "Profile", icon: "pi pi-user" },
    { path: "/analytics", label: "Analytics", icon: "pi pi-chart-bar" },
  ];

  return (
    <div className="card flex justify-content-center bg-green-400 fixed left-0">
      <Sidebar visible={visible} onHide={() => setVisible(false)}>
        <h1>Menu</h1>
        <br />
        {menuItems.map((item) => (
          <a
            key={item.path}
            href={item.path}
            className="flex items-center gap-3"
            style={{
              paddingInline: "50px",
              background: currentPath === item.path ? "#2E8B57" : "#4cd07d", 
              paddingTop: ".5em",
              paddingBottom: ".5em",
              color: "white",
              borderRadius: "1em",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              fontWeight: currentPath === item.path ? "bold" : "normal", 
              transition: "background 0.3s", 
              marginBlock:"1em",
            }}
          >
            <i className={item.icon} style={{ fontSize: "1.2em" }}></i>
            {item.label}
          </a>
        ))}
      </Sidebar>
      <Button icon="pi pi-bars" onClick={() => setVisible(true)} />
    </div>
  );
}
